<template>
  <div
    :class="[
      snackbar.isOpen ? '!opacity-100' : '',
      !snackbar.text ? 'pointer-events-none' : '',
      snackbar.position,
    ]"
    class="snackbar transition-opacity ease-in-out"
  >
    <img
      v-if="snackbar.status === SnackbarStatus.SUCCESS"
      alt=""
      class="img--white"
      src="../../assets/icons/snackbar-success.svg"
    />
    <span class="text-13 font-medium">{{ snackbar.text }}</span>
    <button
      class="!min-h-4 !min-w-4 dark:hidden"
      @click="snackbar.closeSnackbar"
    >
      <img alt="X" class="img--white" src="@/assets/icons/close.svg" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { SnackbarStatus } from "@/stores/snackbar/snackbar.types";

const snackbar = useSnackbarStore();
</script>

<style lang="scss">
.snackbar {
  @apply fixed left-1/2 z-[100] flex -translate-x-1/2 items-center justify-between gap-2 rounded-[10px] bg-black/50 p-3 text-14 font-medium text-white opacity-0 backdrop-blur-[0px] backdrop-blur-[10px]
  dark:rounded-3 dark:bg-black/50 dark:px-4 dark:py-3 dark:backdrop-blur-[10px];
  &.top {
    @apply top-[80px];
  }

  &.bottom {
    @apply bottom-[80px];
  }
}
</style>
