<template>
  <app-dialog v-model="isOpen" class="delete-dialog" width="380">
    <div class="flex flex-col items-start">
      <div
        class="mb-2 flex h-10 w-10 items-center justify-center rounded-full bg-tomato/10"
      >
        <img alt="" src="../../assets/icons/danger.svg" />
      </div>
      <h4 class="mb-1 text-18 font-semibold">Удалить {{ currentEntity }}</h4>
      <p class="text-14 font-medium text-haze">
        Это действие нельзя будет отменить
      </p>
    </div>
    <template #footer>
      <div class="flex w-full justify-end">
        <app-button size="large" type="texted" @click.stop="cancel" @click.self
          >Отменить
        </app-button>
        <app-button
          :loading="isLoading"
          size="large"
          type="texted"
          variant="tomato"
          @click.stop="agree"
          @click.self
          >Удалить
        </app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { useDeleteDialogStore } from "@/stores/delete-dialog";
import { storeToRefs } from "pinia";

const { isOpen, currentEntity, resolve, isLoading } = storeToRefs(
  useDeleteDialogStore(),
);

const cancel = () => {
  resolve.value(false);
  isOpen.value = false;
};

const agree = () => {
  isLoading.value = true;
  resolve.value(true);
};
</script>

<style lang="scss">
.delete-dialog {
  .app-dialog-close {
    display: none;
  }
}
</style>
