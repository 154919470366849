import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import { useUserStore } from "@/stores/user/user";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { Route } from "@/router/router.types";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import cloneDeep from "clone-deep";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const sidebarStore = useSidebarStore();
  const userStore = useUserStore();
  const { showSnackbar } = useSnackbarStore();

  if (!to.name) {
    // Обработка несуществующих страниц
  }

  if (to.meta.sidebars) {
    sidebarStore.currentSidebars = cloneDeep(to.meta.sidebars);
  }

  if (to.matched.some((record) => record.meta.authRequired)) {
    try {
      const response = await userStore.getUser();

      return next();
    } catch (e) {
      // not verified user
      if (e?.response?.status === 418) {
        showSnackbar("Подтвердите почту");
      }

      return next({ name: Route.AUTH });
    }
  }

  next();
});

export default router;
