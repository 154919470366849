<template>
  <div
    class="flex h-[52px] min-h-[52px] w-full items-center justify-between border-b border-lunar bg-white px-5"
  >
    <div class="flex gap-5">
      <button
        v-if="
          (route.meta.sidebars &&
            !route.meta.sidebars.includes(SidebarType.USER_CABINET)) ||
          !route.meta.sidebars
        "
        class="flex h-9 w-9 items-center justify-center rounded-[10px] bg-hoar/10"
        @click="model = !model"
      >
        <img alt="" class="img--hoar" src="@/assets/icons/sidebar.svg" />
      </button>
      <app-button
        v-if="
          route.meta.sidebars &&
          route.meta.sidebars.includes(SidebarType.USER_CABINET)
        "
        size="medium"
        type="half-contained"
        variant="tertiary"
        @click="router.push(`/${Route.KNOWLEDGE_BASE}`)"
      >
        <img
          alt="<"
          class="img--hoar -ml-1 h-[18px] w-[18px] rotate-180"
          src="@/assets/icons/direction/arrow-right-simple.svg"
        />
        <span>Вернуться назад</span>
      </app-button>
      <button>
        <img alt="boostbiz" src="@/assets/logos/boostbiz.png" />
      </button>
    </div>
    <div class="flex gap-3">
      <app-select
        v-if="projectsStore.projects.length"
        v-model="projectsStore.selectedProjectId"
        :options="projectsOptions"
        button-styles="bg-hoar/10 px-1 rounded-[10px]"
        @update:model-value="handleProjectChange"
      >
        <template #button="{ item }">
          <div
            v-if="!Array.isArray(item)"
            class="flex h-9 items-center gap-1.5"
          >
            <div
              :style="{ backgroundColor: item.color }"
              class="flex h-7 w-7 items-center justify-center rounded-[10px]"
            >
              <p class="text-14 font-semibold uppercase text-white">
                {{ item.title[0] }}
              </p>
            </div>
            <p class="mr-1.5 text-13 font-semibold text-hoar">
              {{ item.title }}
            </p>
          </div>
        </template>
        <template #bottom>
          <div
            class="sticky -bottom-1 h-11 w-full border-t border-lunar bg-white p-2"
          >
            <app-button
              class="w-full whitespace-nowrap"
              size="tiny"
              type="texted"
              @click="emit('add-project')"
              >Добавить проект
            </app-button>
          </div>
        </template>
      </app-select>
      <button
        class="relative flex h-9 w-9 items-center justify-center rounded-[10px] bg-hoar/10"
      >
        <img alt="" class="img--hoar" src="@/assets/icons/bell.svg" />
        <span
          class="top absolute -right-[7px] -top-[3px] h-4 rounded-full bg-primary px-[5px] text-11 font-semibold text-white"
          >18</span
        >
      </button>
      <!--      <app-popover1>-->
      <button
        class="flex h-9 items-center justify-center rounded-[10px] bg-hoar/10 px-1"
        @click="router.push(`/${Route.PROJECTS}`)"
      >
        <span
          class="-mt-0.5 flex h-7 w-7 items-center justify-center rounded-[10px] bg-hoar/50"
        >
          <img
            alt=""
            class="img--white mt-2"
            src="@/assets/icons/user/user-main.svg"
          />
        </span>
      </button>
      <!--      </app-popover1>-->
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppSelect from "@/shared/UIKit/app-select/app-select.vue";
import { useProjectsStore } from "@/stores/projects/projects";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { useRoute, useRouter } from "vue-router";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import { Route } from "@/router/router.types";
import { computed, defineModel } from "vue";
import type { IOption } from "@/shared/UIKit/app-select/app-select.types";


const model = defineModel<boolean>({ required: true });

const route = useRoute();
const router = useRouter();
const projectsStore = useProjectsStore();
projectsStore.getProjects();

const emit = defineEmits<{
  "add-project": [];
}>();

const projectsOptions = computed((): IOption[] => {
  return projectsStore.projects.map((item) => ({
    ...item,
    title: item.name,
    value: item.id,
  }));
});

const handleProjectChange = () => {
  localStorage.setItem("project-id", projectsStore.selectedProjectId);
};
</script>

<style lang="scss" scoped></style>
