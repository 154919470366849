import accessesIcon from "@/assets/icons/navigation/accesses.svg";
import employeesIcon from "@/assets/icons/navigation/employees.svg";
import businessProcessesIcon from "@/assets/icons/navigation/business-processes.svg";
import gradingIcon from "@/assets/icons/navigation/grading.svg";
import orgStructureIcon from "@/assets/icons/navigation/org-structure.svg";
import knowledgeBaseIcon from "@/assets/icons/navigation/knowledge-base.svg";
import workBoardIcon from "@/assets/icons/navigation/work-board.svg";
import workflowIcon from "@/assets/icons/navigation/workflow.svg";
import plusIcon from "@/assets/icons/settings-plus.svg";
import projectIcon from "@/assets/icons/project.svg";
import { Route } from "@/router/router.types";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";

export interface NavigationItem {
  title: string;
  icon: string;
  link?: string;
  subItems?: NavigationItem[];
  sidebar?: SidebarType;
  type?: "primary";
}

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    title: "Рабочий стол",
    icon: workBoardIcon,
  },
  {
    title: "Оргструктура",
    icon: orgStructureIcon,
    subItems: [
      {
        title: "Создать оргструктуру",
        icon: plusIcon,
        link: `/${Route.ORG_STRUCTURE}`,
        type: "primary",
      },
      {
        title: "Рабочая область",
        icon: workflowIcon,
      },
      {
        title: "Доступы",
        icon: accessesIcon,
      },
      {
        title: "Грейдирование",
        icon: gradingIcon,
      },
      {
        title: "Сотрудники",
        icon: employeesIcon,
      },
    ],
  },
  {
    title: "База знаний",
    icon: knowledgeBaseIcon,
    link: `/${Route.KNOWLEDGE_BASE_ADMIN}/`,
    sidebar: SidebarType.KNOWLEDGE_BASE,
  },
  {
    title: "Бизнес-процессы",
    icon: businessProcessesIcon,
    link: `/${Route.BUSINESS_PROCESS}`,
  },
];

export const USER_CABINET_NAVIGATION_ITEMS: NavigationItem[] = [
  // {
  //   title: "",
  //   icon: userIcon,
  //   link: `/${Route.USER_PROFILE}`,
  // },
  {
    title: "Управление проектами",
    icon: projectIcon,
    link: `/${Route.PROJECTS}`,
  },
  // {
  //   title: "База знаний",
  //   icon: knowledgeBaseIcon,
  //   link: `/${Route.KNOWLEDGE_BASE}/`,
  //   sidebar: SidebarType.KNOWLEDGE_BASE,
  // },
  // {
  //   title: "Бизнес-процессы",
  //   icon: businessProcessesIcon,
  //   link: `/${Route.BUSINESS_PROCESS}`,
  // },
];
