<template>
  <app-input
    v-model="model"
    :placeholder="placeholder"
    :size="size"
    class="!focus-within:border-none"
  >
    <template #prepend>
      <img
        v-if="!loading"
        alt="Поиск"
        class="img--hoar h-[18px] min-h-[18px] w-[18px] min-w-[18px]"
        src="@/assets/icons/search.svg"
      />
      <img
        v-else
        alt="Загрузка.."
        class="h-[18px] min-h-[18px] w-[18px] min-w-[18px] animate-spin"
        src="@/assets/icons/loaders/primary-loader.svg"
      />
    </template>
    <template #append>
      <slot name="append" />
      <button v-show="model" @click="model = ''">
        <img
          alt="Очист."
          class="img--hoar min-h-5 min-w-5"
          src="@/assets/icons/close-filled.svg"
        />
      </button>
    </template>
  </app-input>
</template>

<script lang="ts" setup>
import AppInput from "@/shared/UIKit/app-input.vue";

interface Props {
  size?: "small" | "base";
  placeholder?: string;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: "base",
  placeholder: "Поиск",
  loading: false,
});
const model = defineModel<string>();
</script>

<style lang="scss" scoped>
::v-deep(.app-input-container) {
  &:focus-within {
    @apply border-grizzle #{!important};
  }
}
</style>
