<script lang="ts" setup>
import { RouterView, useRoute } from "vue-router";
import AppLayout from "@/layouts/app-layout.vue";
import { computed, watch } from "vue";
import { Layout } from "@/layouts/layouts.types";
import BlankLayout from "@/layouts/blank-layout.vue";
import ru from "dayjs/locale/ru";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Theme } from "@/router/router.types";
import EmptyLayout from "@/layouts/empty-layout.vue";

dayjs.locale(ru);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
const route = useRoute();

// watch(
//   () => route.path,
//   () => {
//     if (route.meta.theme) {
//       document.documentElement.classList.add(route.meta.theme);
//     } else {
//       document.documentElement.classList.remove(Theme.DARK);
//     }
//   },
//   {
//     immediate: true,
//     deep: true,
//   },
// );

const resolveLayout = computed(() => {
  switch (route.meta.layout) {
    case Layout.BLANK:
      return BlankLayout;
    case Layout.EMPTY:
      return EmptyLayout;
    default:
      return AppLayout;
  }
});
</script>

<template>
  <component :is="resolveLayout">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
</template>

<style>
@import "@vue-flow/core/dist/style.css";
@import "@vue-flow/core/dist/theme-default.css";
@import "@vue-flow/controls/dist/style.css";
</style>
