import type {
  CreateQuestionFormDTO,
  CreateQuestionFormTemplateDTO,
  QuestionDTO,
} from "@boostbiz/question_form_microservice/client/schemas";
import {
  QuestionFormStatuses,
  QuestionType,
} from "@boostbiz/testing_form_microservice/client/consts";
import dayjs from "dayjs";
import { nanoid } from "nanoid";

export const getDefaultSurveyQuestion = (): QuestionDTO => {
  return {
    text: "",
    id: nanoid(),
    answers: [""],
    files: [],
    filesInfo: [],
    answerType: QuestionType.variant,
    allowMultipleAnswers: false,
  };
};

export const getDefaultsSurveyData = (): CreateQuestionFormDTO => {
  return {
    name: "",
    inspectorId: "",
    timer: {
      allow: true,
      minutes: 0,
      seconds: 0,
    },
    formStatus: QuestionFormStatuses.draft,
    deadline: {
      allow: true,
      value: dayjs().add(1, "day").unix(),
    },
    reward: {
      allow: true,
      prizeAmount: 0,
      robbedAmount: 0,
      takeMoney: false,
    },
    responders: {
      all: false,
      positions: [],
      employees: [],
      divisions: [],
      departments: [],
    },
    categories: {
      value: "",
      items: [],
    },
    public: true,
    questions: [getDefaultSurveyQuestion()],
  };
};

export const getDefaultsSurveyTemplateData =
  (): CreateQuestionFormTemplateDTO => {
    return {
      templateSettings: {
        articleId: "",
        price: 0,
        active: false,
        placeholders: {
          inspectorId: "",
          responders: {
            all: false,
            departments: [],
            divisions: [],
            positions: [],
            employees: [],
          },
        },
      },
      name: "",
      timer: {
        allow: true,
        minutes: 0,
        seconds: 0,
      },
      formStatus: QuestionFormStatuses.draft,
      deadline: {
        allow: true,
        value: dayjs().add(1, "day").unix(),
      },
      reward: {
        allow: true,
        prizeAmount: 0,
        robbedAmount: 0,
        takeMoney: false,
      },
      categories: {
        value: "",
        items: [],
      },
      public: true,
      questions: [getDefaultSurveyQuestion()],
    };
  };
