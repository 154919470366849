<template>
  <div
    :class="[`app-checkbox--${type}`]"
    class="app-checkbox group relative flex w-fit shrink-0 cursor-pointer flex-row-reverse items-start gap-3"
    @click="handleCheckboxChange"
  >
    <input
      id="myCheckbox"
      :checked="modelValue"
      :class="{ 'opacity-50': disabled }"
      :disabled="disabled"
      type="checkbox"
    />
    <label
      :class="{ 'app-checkbox__label--disabled': disabled }"
      class="leading-5"
      for="myCheckbox"
    >
      <slot :disabled="disabled" />
    </label>
    <div
      :class="{
        'app-checkbox--active': modelValue,
        'app-checkbox--disabled': disabled,
      }"
      class="min-h-5 min-w-5 rounded-[6px] border border-grizzle bg-white duration-200 group-hover:bg-[#FCFCFC] dark:border-hoar/15 dark:bg-hoar/5 dark:group-hover:bg-hoar/5"
    >
      <img
        :class="[
          {
            '!visible': modelValue,
            'img--white dark:img--primary': type === 'primary',
            'img--primary': type === 'white',
          },
          tickClass,
        ]"
        alt="."
        class="app-checkbox--tick invisible"
        src="../../assets/icons/tick/tick.svg"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  type?: "primary" | "white";
  modelValue: boolean;
  disabled?: boolean;
  tickClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: "primary",
  disabled: false,
});

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const handleCheckboxChange = () => {
  emit("update:modelValue", !props.modelValue);
};
</script>

<style lang="scss" scoped>
.app-checkbox {
  &--primary {
    .app-checkbox--active {
      @apply bg-primary hover:bg-primary dark:border-primary/20 dark:bg-primary/5 dark:opacity-30 #{!important};
      &.app-checkbox--disabled {
        @apply bg-primary #{!important};
      }
    }

    .app-checkbox--disabled {
      @apply border-[#EAEAEA] bg-[#FBFBFB] #{!important};
    }
  }

  &--white {
    .app-checkbox--active {
      @apply bg-white hover:bg-white #{!important};
    }

    .app-checkbox--disabled {
      @apply border-[#EAEAEA] bg-[#FBFBFB] #{!important};
    }
  }
}

input[type="checkbox"] {
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 20px;
  z-index: 5;
  cursor: pointer;
}

label {
  position: relative;
  cursor: pointer;
  display: inline-block;

  &.app-checkbox__label--disabled {
    &:before {
      opacity: 0.5;
    }
  }
}
</style>
