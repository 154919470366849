<template>
  <button
    :class="[
      `app-button--${variant}`,
      `app-button--${size}`,
      `app-button--${type}`,
      { 'app-button--disabled': disabled },
      { 'app-button--loading': loading },
      { 'app-button--icon': icon },
      { 'app-button--right': !!slots['content-right'] },
    ]"
    :type="action"
    class="app-button"
  >
    <template v-if="loading">
      <img :src="resolveCurrentLoader()" alt="Загрузка" class="animate-spin" />
    </template>
    <template v-else>
      <span v-if="!!slots['content-right']" />
      <slot />
      <slot name="content-right" />
    </template>
  </button>
</template>

<script lang="ts" setup>
import { useSlots } from "vue";
import primaryLoader from "@/assets/icons/loaders/primary-loader.svg";
import whiteLoader from "@/assets/icons/loaders/white-loader.svg";
import tomatoLoader from "@/assets/icons/loaders/tomato-loader.svg";
import tertiaryLoader from "@/assets/icons/loaders/tertiary-loader.svg";

interface Props {
  variant?: "primary" | "tomato" | "tertiary";
  type?: "contained" | "half-contained" | "outlined" | "texted";
  size?: "tiny" | "small" | "medium" | "base" | "large" | "extra-large";
  icon?: boolean;
  disabled?: boolean;
  loading?: boolean;
  action?: "button" | "submit" | "reset";
}

const props = withDefaults(defineProps<Props>(), {
  variant: "primary",
  type: "contained",
  size: "base",
  icon: false,
  loading: false,
  disabled: false,
  action: "button",
});

const slots = useSlots();

const resolveCurrentLoader = () => {
  if (props.type === "contained") {
    return whiteLoader;
  }

  if (props.type === "texted") {
    return tertiaryLoader;
  }

  if (props.variant === "primary") {
    return primaryLoader;
  }
  if (props.variant === "tomato") {
    return tomatoLoader;
  }
};
</script>

<style lang="scss">
.app-button {
  @apply flex items-center justify-center gap-2 px-4 py-3 font-medium text-white duration-200;

  &--right {
    padding-right: 6px !important;
    justify-content: space-between;

    & > :last-child {
      margin-left: 12px;
    }
  }

  &--tiny {
    @apply h-[28px] rounded-[100px]  text-13;
  }

  &--small {
    @apply h-8 rounded-[100px]  text-13;
  }

  &--medium {
    @apply h-9 rounded-[10px]  text-13;
  }

  &--base {
    @apply h-10 min-h-10 rounded-[10px]  text-13 font-semibold;
  }

  &--large {
    @apply h-[42px] rounded-[10px]  text-15 font-semibold;
  }

  &--extra-large {
    @apply h-[52px] rounded-[10px]  text-14;
  }

  &--icon {
    @apply shrink-0 p-0;

    img {
      @apply h-5 w-5;
    }

    &.app-button--tertiary {
      @apply bg-transparent;

      &:hover {
        @apply bg-hoar/10;
      }

      &:active {
        @apply bg-hoar/15;
      }
    }

    &.app-button--tiny {
      @apply h-[28px] min-h-[28px] w-[28px] min-w-[28px]  rounded-[10px];
    }

    &.app-button--small {
      @apply h-8 w-8  rounded-[10px];
    }

    &.app-button--medium {
      @apply h-9 w-9  rounded-[10px];
    }

    &.app-button--base {
      @apply h-10 min-h-10 w-10 min-w-10  rounded-[10px];
    }

    &.app-button--large {
      @apply h-[42px] w-[42px]  rounded-[10px];
    }
  }

  &--primary {
    @apply bg-primary;

    &.app-button--contained {
      &:hover {
        @apply bg-azure;
      }

      &:active {
        @apply bg-cobalt;
      }
    }

    &.app-button--outlined {
      @apply border border-primary bg-white text-primary;
      &:hover {
        @apply bg-primary/10;
      }

      &:active {
        @apply bg-primary/15;
      }
    }

    &.app-button--texted {
      @apply bg-transparent text-primary;
      &:hover {
        @apply bg-primary/10;
      }

      &:active {
        @apply bg-primary/15;
      }
    }

    &.app-button--half-contained {
      @apply bg-primary/10 text-primary;
      &:hover {
        @apply bg-primary/15;
      }

      &:active {
        @apply bg-primary/20;
      }
    }
  }

  &--tomato {
    @apply bg-tomato;

    &.app-button--contained {
      &:hover {
        @apply bg-florid;
      }

      &:active {
        @apply bg-scarlet;
      }
    }

    &.app-button--outlined {
      @apply border border-tomato bg-white text-tomato;
      &:hover {
        @apply bg-tomato/10;
      }

      &:active {
        @apply bg-tomato/15;
      }
    }

    &.app-button--texted {
      @apply bg-white text-tomato;
      &:hover {
        @apply bg-tomato/10;
      }

      &:active {
        @apply bg-tomato/15;
      }
    }

    &.app-button--half-contained {
      @apply bg-tomato/10 text-tomato;
      &:hover {
        @apply bg-tomato/15;
      }

      &:active {
        @apply bg-tomato/20;
      }
    }
  }

  &--tertiary {
    @apply bg-hoar;

    &.app-button--contained {
      @apply text-white;

      &:hover {
        @apply bg-slate;
      }

      &:active {
        @apply bg-steel;
      }
    }

    &.app-button--outlined {
      @apply border border-hoar bg-white text-hoar;
      &:hover {
        @apply bg-hoar/10;
      }

      &:active {
        @apply bg-hoar/15;
      }
    }

    &.app-button--texted {
      @apply bg-white text-hoar;

      &:hover {
        @apply bg-hoar/10;
      }

      &:active {
        @apply bg-hoar/15;
      }
    }

    &.app-button--half-contained {
      @apply bg-hoar/10 text-hoar;
      
      &:hover {
        @apply bg-hoar/15;
      }

      &:active {
        @apply bg-hoar/20;
      }
    }
  }

  &.app-button--disabled {
    @apply pointer-events-none opacity-40;
  }

  &.app-button--loading {
    @apply pointer-events-none;
  }
}
</style>
