<template>
  <div
    :class="{ '!pt-[60px]': shouldShowHeader }"
    class="flex h-screen w-screen p-10 dark:bg-midnight"
  >
    <header
      v-if="shouldShowHeader"
      :class="{ '!h-[52px]': shouldShowHeader }"
      class="fixed left-0 right-0 top-0 z-[100] flex h-[60px] w-full items-center justify-center border-b border-b-lunar bg-white dark:border-b-hoar/10 dark:bg-base/10"
    >
      <button
        v-if="shouldShowBackArrow"
        class="fixed left-6 top-[26px] flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full duration-200 hover:bg-hoar/15 active:bg-hoar/20"
        @click="goBack"
      >
        <img
          alt=""
          class="img--hoar h-5 w-5"
          src="@/assets/icons/direction/arrow-right.svg"
        />
      </button>
      <img
        alt="BOOSTBIZ"
        class="hidden h-4 w-[111px] dark:flex"
        src="@/assets/icons/logo-dark.svg"
      />
      <img
        alt="BOOSTBIZ"
        class="flex h-4 w-[111px] dark:hidden"
        src="@/assets/icons/logo.svg"
      />
    </header>
    <slot />
  </div>
  <app-snackbar />
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import AppSnackbar from "@/shared/UIKit/app-snackbar.vue";

const route = useRoute();
const router = useRouter();

const shouldShowHeader = computed(() => route.meta.showHeader);
const shouldShowBackArrow = computed(() => route.meta.showBackArrow);

const goBack = () => {
  router.go(-1);
};
</script>

<style lang="scss" scoped></style>
