<template>
  <div class="h-screen w-screen">
    <app-header
      v-show="!store.isFullScreen"
      v-model="isNavigationOpen"
      @add-project="isCreateProjectDialogOpen = true"
    />
    <div
      :class="{ 'h-[calc(100vh-52px)] p-5': !store.isFullScreen }"
      class="flex gap-5"
    >
      <app-navigation v-if="isNavigationOpen && !store.isFullScreen" />
      <div class="flex w-full grow flex-col overflow-hidden">
        <slot />
      </div>
      <app-snackbar />
      <delete-dialog />
      <confirm-dialog />
      <create-project-dialog v-model="isCreateProjectDialogOpen" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppNavigation from "@/layouts/navigation/app-navigation.vue";
import AppSnackbar from "@/shared/UIKit/app-snackbar.vue";
import DeleteDialog from "@/features/dialogs/delete-dialog.vue";
import ConfirmDialog from "@/layouts/confirm-dialog.vue";
import AppHeader from "@/layouts/headers/app-header.vue";
import { ref } from "vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import CreateProjectDialog from "@/features/dialogs/create-project-dialog.vue";

const isCreateProjectDialogOpen = ref(false);
const store = useSidebarStore();

const isNavigationOpen = ref(true);
</script>

<style lang="scss" scoped></style>
