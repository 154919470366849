<template>
  <navigation-layout
    :class="{ '!translate-x-0': isSidebarOpened }"
    :level="1"
    class="translate-x-full duration-200"
    is-sub-layout
    title="Личный кабинет"
  >
    <div class="m-1.5 flex grow flex-col gap-1 overflow-y-auto">
      <app-navigation-item
        v-for="item in USER_CABINET_NAVIGATION_ITEMS"
        :key="item.title"
        :item="item"
      />
    </div>
  </navigation-layout>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import { USER_CABINET_NAVIGATION_ITEMS } from "@/layouts/navigation/navigation.data";
import AppNavigationItem from "@/layouts/navigation/app-navigation-item.vue";

const sidebarStore = useSidebarStore();

const isSidebarOpened = computed(
  () =>
    sidebarStore.currentSidebars[sidebarStore.currentSidebars.length - 1] ===
    SidebarType.USER_CABINET,
);
</script>

<style lang="scss" scoped></style>
