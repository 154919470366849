import { ref } from "vue";
import { defineStore } from "pinia";
import type { Position as PositionType } from "@vue-flow/core";
import { Position } from "@vue-flow/core";
import { SnackbarStatus } from "@/stores/snackbar/snackbar.types";

export const useSnackbarStore = defineStore("snackbar", () => {
  const isOpen = ref(false);
  const text = ref("");
  const snackbarPosition = ref();
  const status = ref();

  let timerId: number = 1;

  const showSnackbar = (
    message?: string,
    position: PositionType = Position.Top,
    timeout = 5000,
    snackbarStatus?: SnackbarStatus | null,
  ) => {
    isOpen.value = true;
    text.value = message ?? "";
    snackbarPosition.value = position;
    status.value = snackbarStatus;

    timerId = window.setTimeout(() => {
      isOpen.value = false;
      status.value = null;
    }, timeout);
  };

  const closeSnackbar = () => {
    isOpen.value = false;
    status.value = null;
    clearTimeout(timerId);
  };

  return {
    isOpen,
    position: snackbarPosition,
    showSnackbar,
    closeSnackbar,
    text,
    status,
  };
});
