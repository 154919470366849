import { DocumentType } from "@boostbiz/wiki_client/schemas/documents/documents.schema";
import articleIcon from "@/assets/icons/file-text.svg";
import folderIcon from "@/assets/icons/folder.svg";
import linkIcon from "@/assets/icons/text-edit/link.svg";

export const getDocumentIcon = (docType: DocumentType) => {
  switch (docType) {
    case DocumentType.Article:
      return articleIcon;
    case DocumentType.Folder:
      return folderIcon;
    case DocumentType.Link:
      return linkIcon;
  }
};

export const getNewTitle = (docType: DocumentType) => {
  switch (docType) {
    case DocumentType.Folder:
      return "Новая папка";
    case DocumentType.Article:
      return "Новый документ";
    case DocumentType.Link:
      return "Новая ссылка";
  }
};
