import { authApi } from "@/api";
import type { UserResponse } from "@boostbiz/auth/schemas/index.schema";
import { defineStore } from "pinia";
import { ref } from "vue";
import type { UserStore } from "./user.types";
import { useRouter } from "vue-router";
import { Route } from "@/router/router.types";

export const useUserStore = defineStore("user", (): UserStore => {
  const router = useRouter();

  const user = ref<UserResponse | null>(null);

  const getUser = async () => {
    user.value = await authApi.common.getMe();
  };

  const logout = async () => {
    await authApi.common.logout();
    localStorage.removeItem("token");
    router.push(`/${Route.AUTH}`);
  };

  return {
    user,
    getUser,
    logout,
  };
});
