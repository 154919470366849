<template>
  <navigation-layout
    :class="{ '!translate-x-0': isSidebarOpened }"
    :level="2"
    class="translate-x-full duration-200"
    is-sub-layout
    title="Новый шаблон опроса"
  >
    <template #header>
      <button @click="handleBackClick">
        <img
          alt=""
          class="img--hoar absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer"
          src="@/assets/icons/direction/arrow-right-long.svg"
        />
      </button>
    </template>

    <template #default>
      <div class="m-1.5">
        <settings-questions-tabs />
      </div>
      <div class="grid grid-cols-2 gap-1.5 border-y border-y-lunar p-1.5">
        <action-button
          v-for="action in CREATE_TEMPLATE_ACTIONS"
          :key="action.value"
          :action="action"
          :active="action.value === CreateAction.CREATE"
          :disabled="isPreviewLoading || isCreateLoading"
          :loading="resolveCurrentActionLoading(action.value as CreateAction)"
          @click="resolveCurrentAction(action.value as CreateAction)"
        />
      </div>
    </template>
  </navigation-layout>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { storeToRefs } from "pinia";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import SettingsQuestionsTabs from "@/layouts/sidebars/knowledge-base-create-sidebar/settings-questions-tabs.vue";
import {
  CREATE_TEMPLATE_ACTIONS,
  CreateAction,
} from "@/layouts/sidebars/knowledge-base-create-sidebar/knowledge-testing-survey-sidebar.data";
import ActionButton from "@/modules/knowledge-base/views/testing-survey/components/action-button.vue";
import { useKnowledgeBaseStore } from "@/stores/knowledge-base/knowledge-base";

import { surveyApi } from "@/api";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { Route } from "@/router/router.types";
import { useRouter } from "vue-router";
import type { QuestionFormDTO } from "@boostbiz/question_form_microservice/client/schemas";

const router = useRouter();

const { showSnackbar } = useSnackbarStore();

const sidebarStore = useSidebarStore();
const knowledgeBaseStore = useKnowledgeBaseStore();

const { currentSidebars } = storeToRefs(sidebarStore);

const isSidebarOpened = computed(
  () =>
    currentSidebars.value[currentSidebars.value.length - 1] ===
    SidebarType.KNOWLEDGE_BASE_SURVEY_TEMPLATE_CREATE,
);

const handleBackClick = () => {
  sidebarStore.goBack();
};

const isCreateLoading = ref(false);
const isPreviewLoading = ref(false);

const resolveCurrentActionLoading = (action: CreateAction) => {
  switch (action) {
    case CreateAction.CREATE:
      return isCreateLoading.value;
    case CreateAction.PREVIEW:
      return isPreviewLoading.value;
  }
};

const createSurvey = async () => {
  if (
    knowledgeBaseStore.surveyTemplateFormRef!.validate() &&
    knowledgeBaseStore.surveyTemplateQuestionsRef!.validate()
  ) {
    isCreateLoading.value = true;

    try {
      await surveyApi.templates.createTemplate(
        knowledgeBaseStore.surveyTemplate,
      );

      showSnackbar("Шаблон опрос успешно создан");
    } catch (e) {
      showSnackbar(e?.response?.data?.message);
    } finally {
      isCreateLoading.value = false;
    }
  }
};

const previewSurvey = async () => {
  isPreviewLoading.value = true;
  knowledgeBaseStore.surveyPreview =
    knowledgeBaseStore.surveyTemplate as unknown as QuestionFormDTO;
  await router.push(`/${Route.KNOWLEDGE_BASE_SURVEY_PREVIEW}`);
  isPreviewLoading.value = false;
};

const resolveCurrentAction = (action: CreateAction) => {
  switch (action) {
    case CreateAction.CREATE:
      return createSurvey();
    case CreateAction.PREVIEW:
      return previewSurvey();
  }
};
</script>

<style lang="scss" scoped></style>
