<template>
  <Listbox
    v-slot="slotProps"
    :class="[
      `app-select--${size}`,
      {
        'app-select--disabled': disabled,
        'app-select--error': error || errorMessage,
      },
    ]"
    :model-value="computedModel"
    :multiple="multiple"
    by="value"
    class="app-select"
    @update:model-value="handleSelectChange"
  >
    <div class="relative">
      <span
        v-if="errorMessage"
        class="absolute -bottom-5 left-[6px] text-11 font-medium text-tomato"
        >{{ errorMessage }}</span
      >
      <ListboxButton
        ref="referenceRef"
        :class="[
          {
            'app-select-button--open': slotProps.open,
            'app-select-button': !$slots.button,
          },
          buttonStyles,
        ]"
        as="div"
        class="flex cursor-pointer items-center"
      >
        <slot
          :item="computedModel"
          :open="slotProps.open"
          :title="(computedModel as IOption).title"
          name="button"
        >
          <div
            v-if="isModelNotEmpty"
            :class="{ truncate: !chip }"
            class="app-select-value truncate"
          >
            <span v-if="!Array.isArray(model)">
              {{ (computedModel as IOption).title }}
            </span>
            <div v-else :class="{ truncate: !chip }">
              <div v-if="chip" class="flex flex-wrap gap-1">
                <button
                  v-for="option in model"
                  v-if="chip"
                  class="flex h-6 items-center gap-1 text-nowrap rounded-[6px] bg-hoar/10 px-2 py-0.5 hover:bg-hoar/20"
                  @click.stop="handleDeleteChip(option)"
                >
                  <p class="text-12 font-medium text-hoar">
                    {{ getTitleByModel(option) }}
                  </p>
                  <img
                    alt=""
                    class="img--hoar"
                    src="@/assets/icons/close.svg"
                  />
                </button>
              </div>
              <p v-else class="truncate">
                <span v-for="(option, i) in model" :key="option">
                  {{ getTitleByModel(option) }}
                  <span v-if="i !== model.length - 1">, </span>
                </span>
              </p>
              <span
                v-if="!model.length && placeholder"
                class="text-14 font-medium text-hoar"
                >{{ placeholder }}</span
              >
            </div>
          </div>
        </slot>
        <span
          v-if="!isModelNotEmpty && placeholder"
          class="text-14 font-medium text-hoar"
          >{{ placeholder }}</span
        >
        <span
          v-if="!isModelNotEmpty && label"
          class="text-14 font-medium text-white"
          >{{ label }}</span
        >
        <label
          v-if="label"
          :class="{ 'floating-label--lower': isModelNotEmpty }"
          class="floating-label"
        >
          {{ label }}
        </label>
        <img
          v-if="!disabled && !noIcon"
          :class="{
            'rotate-180': Boolean($slots.button) && slotProps.open,
            '!img--primary rotate-180': slotProps.open && !$slots.button,
          }"
          alt="^"
          class="app-select-arrow img--hoar h-[18px] w-[18px] duration-200"
          src="@/assets/icons/direction/arrow-bottom-simple.svg"
        />
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          ref="floatingRef"
          :class="{
            '!pointer-events-auto !opacity-100': slotProps.open,
            'min-w-[300px]': search,
          }"
          :style="floatingStyles"
          class="app-select-list relative"
          static
          @click.stop
        >
          <div v-if="search" class="px-4 py-2">
            <app-search v-model="searchValue" size="small" />
          </div>
          <template v-if="computedOptions.length">
            <ListboxOption
              v-for="option in computedOptions"
              :key="option.title"
              v-slot="{ active, selected }"
              :value="option"
            >
              <li
                :class="{ selected: selected }"
                class="flex cursor-pointer items-center justify-between gap-3 whitespace-nowrap px-4 py-[10px] text-14 font-medium leading-5 duration-200 hover:bg-background dark:text-white dark:hover:bg-base/10"
              >
                <div
                  v-if="!multiple && selectedIconPosition == 'left'"
                  class="h-[18px] w-[18px]"
                >
                  <img
                    v-if="selected"
                    alt="Выбрано"
                    class="img--primary"
                    src="@/assets/icons/tick/tick-circle-filled.svg"
                  />
                </div>
                <app-checkbox v-if="multiple" :model-value="selected" />
                <slot :option="{ ...option, active, selected }" name="option">
                  <p :class="{ 'mr-auto': selectedIconPosition == 'left' }">
                    {{ option.title }}
                  </p>
                </slot>
                <div
                  v-if="!multiple && selectedIconPosition == 'right'"
                  class="ml-5 h-[18px] w-[18px]"
                >
                  <img
                    v-if="selected"
                    alt="Выбрано"
                    class="img--primary"
                    src="@/assets/icons/tick/tick-circle-filled.svg"
                  />
                </div>
              </li>
            </ListboxOption>
          </template>
          <p
            v-else
            class="flex items-center justify-center whitespace-nowrap px-4 py-[10px] text-13 font-medium leading-5 text-hoar dark:text-white"
          >
            Ничего не найдено
          </p>
          <slot name="bottom" />
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script lang="ts" setup>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { computed, ref } from "vue";
import type { IOption } from "@/shared/UIKit/app-select/app-select.types";
import { autoUpdate, flip, offset, shift, useFloating } from "@floating-ui/vue";
import AppSearch from "@/shared/UIKit/app-search.vue";
import AppCheckbox from "@/shared/UIKit/app-checkbox.vue";

interface Props {
  options: IOption[];
  placeholder?: string;
  multiple?: boolean;
  label?: string;
  search?: boolean;
  disabled?: boolean;
  error?: boolean;
  size?: "base" | "small";
  chip?: boolean;
  errorMessage?: string;
  buttonStyles?: string;
  selectedIconPosition?: "right" | "left" | "none";
  noIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  multiple: false,
  placeholder: "",
  label: "",
  disabled: false,
  search: false,
  readonly: false,
  errorMessage: "",
  error: false,
  size: "base",
  chip: false,
  buttonStyles: "",
  noIcon: false,
  selectedIconPosition: "right",
});

const referenceRef = ref();
const floatingRef = ref();
const model = defineModel<null | string | string[]>();
const isModelNotEmpty = computed(() => {
  if (Array.isArray(model.value)) {
    return model.value.length > 0;
  }

  return model.value;
});

const computedOptions = computed(() => {
  if (props.search) {
    return props.options.filter((o) =>
      o.title.toLowerCase().includes(searchValue.value.toLowerCase()),
    );
  }
  return props.options;
});

const computedModel = computed((): IOption | IOption[] => {
  if (Array.isArray(model.value)) {
    return model.value.map((value: string) => {
      return {
        value: value,
        title: props.options.find((item) => item.value === value)?.title ?? "",
      };
    });
  }

  const option = props.options.find((item) => item.value === model.value);

  return {
    ...option,
    value: model.value ?? "",
    title: option?.title ?? "",
  };
});

const handleSelectChange = (newValue: IOption | IOption[]) => {
  if (!props.multiple) {
    model.value = (newValue as IOption).value;
    return;
  }

  model.value = newValue.map((item: IOption) => item.value);
};

const searchValue = ref("");

const { floatingStyles } = useFloating(referenceRef, floatingRef, {
  placement: "bottom",
  middleware: [offset(4), shift({ padding: 0 }), flip()],
  whileElementsMounted(referenceEl, floatingEl, update) {
    return autoUpdate(referenceEl, floatingEl, update, {
      layoutShift: false,
    });
  },
});

const getTitleByModel = (value: string): string => {
  return props.options.find((option) => option.value === value)?.title ?? "";
};

const handleDeleteChip = (value: string) => {
  const option = props.options.find((option) => option.value === value)!;
  if (option.notDeletable) {
    return;
  }
  model.value = (model.value as string[])!.filter((item) => item !== value);
};
</script>

<style lang="scss" scoped>
.app-select-button {
  @apply flex min-h-[42px] w-full items-center justify-between gap-3 rounded-[10px] border border-grizzle bg-white px-4 py-2 text-14 font-medium transition-all;

  &:hover {
    @apply border-smoke;
  }

  &--open {
    @apply border-primary #{!important};

    .floating-label {
      @apply -translate-x-1 -translate-y-5 bg-white px-1 text-11 font-medium text-primary;
    }
  }
}

.floating-label {
  @apply pointer-events-none absolute  left-4 top-3 z-[5] flex h-min w-min items-center justify-center text-nowrap text-14 font-medium text-hoar transition-all duration-300 dark:opacity-50;

  &--lower {
    @apply -translate-x-1 -translate-y-5 bg-white px-1 text-11 font-medium;
  }
}

.app-select-value {
  @apply flex flex-wrap gap-1;
}

.app-select-list {
  @apply pointer-events-none absolute z-[11] flex max-h-[240px] min-w-full flex-col overflow-y-auto rounded-[10px] border border-grizzle bg-white py-1 opacity-0 shadow-misty-veil outline-none duration-200 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-haze dark:border-hoar/20 dark:bg-midnight p-1.5;

  li {
    @apply rounded-[10px];
  }
}

.app-select {
  &--disabled {
    .app-select-button {
      @apply pointer-events-none;
    }

    &:not(:has(.app-select-value)) {
      .app-select-button {
        @apply border-grizzle/70 bg-background;
      }
    }

    &:has(.app-select-value) {
      .app-select-button {
        @apply border-grizzle/70 bg-white;
      }

      .app-select-value {
        @apply text-hoar;
      }
    }
  }

  &--small {
    .app-select-button {
      @apply h-9 min-h-9 px-3 text-13 #{!important};

      .floating-label {
        @apply left-3 text-13;

        &--lower {
          @apply left-4 -translate-y-[18px] text-11 #{!important};
        }
      }

      &--open {
        .floating-label {
          @apply left-4 -translate-y-[18px]  #{!important};
        }
      }

      &:has(input:focus) {
        .floating-label {
          @apply left-4 #{!important};
        }
      }

      //input {
      //  @apply text-13 #{!important};
      //
      //  &::placeholder {
      //    @apply text-13 #{!important};
      //  }
      //}
    }
  }

  &--error {
    .floating-label {
      @apply text-tomato;
    }

    .app-select-button {
      @apply border-tomato #{!important};
    }
  }
}
</style>
