<template>
  <div
    class="w-[300px] rounded-[10px] border border-lunar bg-white shadow-misty-veil"
  >
    <Form
      :validation-schema="schema"
      class="m-2 flex gap-2"
      @submit="handleSubmit"
    >
      <app-input
        v-model="localLink"
        :error="Boolean(error)"
        class="w-full"
        name="link"
        placeholder="Вставьте ссылку"
        size="small"
      />
      <app-button
        v-if="link"
        icon
        size="medium"
        type="texted"
        variant="tertiary"
        @click="handleDeleteBtnClick"
        ><img
          alt="Удал."
          class="img--hoar"
          src="@/assets/icons/trash-empty.svg"
        />
      </app-button>
      <app-button
        :disabled="!localLink"
        action="submit"
        icon
        size="medium"
        type="texted"
        ><img
          alt="OK"
          class="img--primary"
          src="@/assets/icons/tick/tick-circle.svg"
        />
      </app-button>
    </Form>
  </div>
</template>

<script lang="ts" setup>
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { ref, watch } from "vue";
import AppInput from "@/shared/UIKit/app-input.vue";
import * as Yup from "yup";
import { Form } from "vee-validate";

interface Props {
  link: string;
}

const emit = defineEmits<{
  submit: [link: string];
  delete: [];
}>();

const props = defineProps<Props>();
const error = ref("");
const localLink = ref(props.link ?? "");
const schema = Yup.object().shape({
  link: Yup.string().url("Невалидный URL"),
});

const handleDeleteBtnClick = () => {
  emit("delete");
  localLink.value = "";
};

watch(
  () => props.link,
  () => {
    localLink.value = props.link;
  },
);

const handleSubmit = () => {
  emit("submit", localLink.value);
};
</script>

<style lang="scss" scoped></style>
